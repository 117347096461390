import React from "react";
import { Layout } from "../components/layout";
import { graphql, Link } from "gatsby";
import moment from 'moment';
export const Blog = ({ data }) => {
    const blogs = data.allMdx.edges;
    blogs.sort((n1, n2) => (n2.node.frontmatter.date.localeCompare(n1.node.frontmatter.date)));
    return React.createElement(Layout, { current: "blog" },
        React.createElement("div", { className: "content" },
            React.createElement("table", { className: "blog-table" },
                React.createElement("tbody", null, blogs.map((value, index) => {
                    const node = value.node;
                    return (React.createElement("tr", { key: node.id },
                        React.createElement("td", { align: "left", className: "date-cell" }, moment(node.frontmatter?.date).format("YYYY-MM-DD")),
                        React.createElement("td", { align: "center", className: "dash-cell" }, "\u2013"),
                        React.createElement("td", { align: "left" },
                            React.createElement(Link, { to: node.fields?.slug ?? "/404" }, node.frontmatter?.title))));
                })))));
};
export const query = graphql `
    query BlogMarkdownDocuments {
        allMdx(filter: {fields: {document_type: {eq: "blog"}}}) {
            edges {
                node {
                    id
                    frontmatter {
                        title
                        date
                    }
                    fields {
                        slug
                    }
                }
            }
        }
    }
`;
export default Blog;
